<template>
    <v-card>
        <v-card-title>Create Package</v-card-title>
        <v-card-text>

            <v-form v-model="isValid">
                <v-text-field v-model="form.package_title" :rules="[rules.requiredRule]" label="Package Name"
                    color="secondary">
                </v-text-field>

                <v-row>
                    <v-col cols="12" sm="6" v-for="feature in features" :key="feature">
                        <v-switch hide-details inset color="secondary" :label="feature"
                            :value="form.features.includes(feature)" @change="(e) => featureChange(e, feature)">
                        </v-switch>
                    </v-col>
                </v-row>

                <v-card-actions class="d-flex justify-content-between mt-10">
                    <v-btn color="error" @click="$emit('close')">Close</v-btn>
                    <v-btn color="secondary" :loading="sending" :disabled="sending" @click="formHandler">{{ edit ? "Update" : "Create" }} Package</v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { requiredRule, emailRule } from "../../helpers/form-rules";

export default {
    name: 'create-package',
    props: ['features'],
    data() {
        return {
            isValid: false,
            edit: false,
            sending: false,
            form: {
                package_title: '',
                features: []
            },
            rules: {
                requiredRule,
                emailRule
            },
        }
    },
    methods: {
        featureChange(e, feature) {
            if (e) {
                this.form.features.push(feature)
            } else {
                this.form.features = this.form.features.filter(f => f !== feature)
            }
        },
        async formHandler() {
            if (this.isValid) {
                try {
                    this.sending = true;
                     await this.axios.post('/create-package', this.form)
                    this.$emit('close')
                } catch (error) {
                    console.log(error)
                } finally {
                    this.sending = false;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>