<template>
  <loader :loading="loading">
    <div class="text-right">
      <v-btn color="secondary" class="mx-3" @click="dialog = true">Create Package</v-btn>
    </div>
      <div class="packages mt-5">
        <h2 class="mb-3">Packages</h2>
        <v-row>
          <v-col cols="12" md="4" sm="6" v-for="pkg in packages" :key="pkg._id">
            <v-card>
              <v-card-title>
                {{pkg.package_title}}
              </v-card-title>
              <v-card-text>
                <ul>
                  <li v-for="feature in pkg.features" :key="feature">
                    {{feature}}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

    <v-dialog v-model="dialog" max-width="800px">
      <CreatePackage @close="dialog = false" :features="features" />
    </v-dialog>
  </loader>
</template>

<script>
import CreatePackage from '../../components/system-admin/create-package.vue';
import { createNamespacedHelpers } from 'vuex';
const packageModule = createNamespacedHelpers('package');
export default {
  name: 'packages',
  components: {
    CreatePackage,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...packageModule.mapState(['features', 'packages', 'loading']),
  },
  methods: {
    ...packageModule.mapActions(['FETCH_PACKAGES'])
  },
  created() { 

  },
};
</script>

<style lang="scss" scoped>
</style>
